import React, { useEffect, useState } from "react";
import "./privatesale.css";
import diamond from "../../../media/diamond_privateSale.png";
import professional from "../../../media/professional.png";
import { useNavigate } from "react-router-dom";

const PrivateSaleDetails = ({ details, setDetails }) => {
  const [isAmateur, setAmateur] = useState(true);
  const navigate = useNavigate();

  function highlightLine(index) {
    const highlight = document.querySelector(".highlight");
    highlight.style.width = "50%"; // Set width to 50%
    highlight.style.left = index === 0 ? "0" : "50%"; // Position the highlight
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "700px",
      }}
    >
      <div
        className="privateSalePepe"
        style={{ width: "232px", height: "72px" }}
      >
        Choose your Entrance into the world of Pepe’s Frogbar private sale
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "90%",
            }}
          >
            <div
              onClick={() => {
                setAmateur(true);
                highlightLine(0);
              }}
              className={
                isAmateur
                  ? "privateSaleHeadingSelected"
                  : "privateSaleHeadingNotSelected"
              }
            >
              AMATEUR
            </div>
            <div
              onClick={() => {
                setAmateur(false);
                highlightLine(1);
              }}
              className={
                !isAmateur
                  ? "privateSaleHeadingSelected"
                  : "privateSaleHeadingNotSelected"
              }
            >
              PROFESSIONAL
            </div>
          </div>
        </div>
        <div class="line">
          <div class="highlight"></div>
        </div>
        <div
          style={{
            width: "95%",
            height: "500px",
            borderRadius: "8px",
            backgroundColor: "rgba(33, 17, 52, 0.6)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "center",
          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                style={{ width: "90px", height: "90px" }}
                alt="diamond"
                src={isAmateur ? diamond : professional}
                className="diamondImg"
              />
            </div>
            <div style={{ margin: "0.5rem 0" }}>
              <hr
                style={{
                  border: "1px solid #FFFFFF26",
                  marginLeft: "7px",
                  marginRight: "7px",
                }}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div className="privateSaleHeadings">PACKAGE</div>
                  <div
                    className="privateSaleHeadings"
                    style={{ textAlign: "end" }}
                  >
                    PRICE
                  </div>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #FFFFFF26",
                  marginLeft: "7px",
                  marginRight: "7px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
                padding: "0 5px"
              }}
            >
              {isAmateur ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Espresso
                    </div>
                    <div>1.4 Ton</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Cappuccino
                    </div>
                    <div>2.8 Ton</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Caffe Latte
                    </div>
                    <div>4.5 Ton</div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Beer
                    </div>
                    <div>0.5 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Espresso Martini
                    </div>
                    <div>2 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Aperol Spritz
                    </div>
                    <div>5 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Moscow Mule
                    </div>
                    <div>10 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Mojito
                    </div>
                    <div>15 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Pina Colada
                    </div>
                    <div>25 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Cosmopolitan
                    </div>
                    <div>55 Sol</div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      height: "26px",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 2%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "26px",
                      }}
                    >
                      Manhattan
                    </div>
                    <div>100 Sol</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "1.5rem" }}>
          <div
            onClick={() => navigate("/wallet")}
            style={{
              width: "80%",
              height: "40px",
              borderRadius: "26px",
              background: "#D87500",
              font: "Oswald",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
            }}
          >
            JOIN NOW
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateSaleDetails;
