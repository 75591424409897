const SERVER_ENDPOINT = "https://game-prodapi.pepefrogbar.com";
const WEBSOCKET_ENDPOINT = "wss://game-prodapi.pepefrogbar.com/websockets";
const MINIAPP_LINK = "https://t.me/pepefrogbar_bot/pepefrogbar";
const TON_EXPLORER = "https://toncenter.com/api/v2";
const DAPP_ENDPOINT = "https://dapp-devapi.pepefrogbar.com";
const TON_EXPLORER_KEY =
  "ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67";
const rankToStringMapping = {
  0: "ROOKIE",
  1: "BRONZE",
  2: "SILVER",
  3: "GOLD",
  4: "PLATINUM",
  5: "DIAMOND",
  6: "BLUE DIAMOND",
  7: "CROWN DIAMOND",
};

const energyRankMapping = {
  0: 3600,
  1: 4000,
  2: 4500,
  3: 5000,
  4: 7500,
  5: 10000,
  6: 12500,
  7: 15000,
};
export {
  SERVER_ENDPOINT,
  DAPP_ENDPOINT,
  MINIAPP_LINK,
  WEBSOCKET_ENDPOINT,
  TON_EXPLORER,
  rankToStringMapping,
  TON_EXPLORER_KEY,
  energyRankMapping,
};
