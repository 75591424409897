import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Unsupported from "./app/UnsupportedPage/Unsupported";
import Stats from "./app/PlayPage/StatsModal/Stats";
import PersonalStat from "./app/PlayPage/PersonalStatModal/Personal";
import Wallet from "./app/wallet/Wallet";
import Friend from "./app/PlayPage/FriendModal/Friend";
import Faq from "./app/PlayPage/FaqModal/Faq";
import Tasks from "./app/PlayPage/TasksModal/tasks";
import Profile from "./app/PlayPage/ProfileModal/profile";
import Ranking from "./app/PlayPage/RankingModal/ranking";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { UserContextProvider } from "./context/UserContext";
import { Toaster } from "react-hot-toast";
import { AuthContextProvider } from "./context/AuthContext";
import PrivateSale from "./app/PlayPage/PrivateSaleModal/privatesale";

const manifestUrl =
  "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App key="page1" />,
  },
  {
    path: "/unsupported",
    element: <Unsupported key="page2" />,
  },
  {
    path: "/stats",
    element: <Stats key="page3" />,
  },
  {
    path: "/task",
    element: <Tasks key="page4" />,
  },
  {
    path: "/rank",
    element: <Ranking key="page5" />,
  },
  {
    path: "/personal",
    element: <PersonalStat key="page6" />,
  },
  {
    path: "/profile",
    element: <Profile key="page7" />,
  },
  {
    path: "/wallet",
    element: <Wallet key="page8" />,
  },
  {
    path: "/friend",
    element: <Friend key="page9" />,
  },
  {
    path: "/faq",
    element: <Faq key="page10" />,
  },
  {
    path: "/privatesale",
    element: <PrivateSale key="page11" />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <UserContextProvider>
        <AuthContextProvider>
          <Toaster />
          <RouterProvider router={router} />
        </AuthContextProvider>
      </UserContextProvider>
    </TonConnectUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
