import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./profile.css";
import moneyCoin from "../../../media/silver-pepe-bg.png";
import handIcon from "../../../media/hand.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import toast from "react-hot-toast";
import { MdCheck } from "react-icons/md";
import { useSpring, animated } from "@react-spring/web";
import countryList from "react-select-country-list";
import Select from "react-select";
import handIcon2 from "../../../media/hand2.png";
import profileIcon from "../../../media/gold.png";
import cameraIcon from "../../../media/camera.png";
import manImg from "../../../media/man.png";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import frogImg from "../../../media/frogImg.png";
import Popup from "../../Shared/CongratsPopup/Popup";
import party_popper from "../../../media/party_popper.png";
import Confetti from "react-confetti";
import bronzeIcon from "../../../media/bronze.png";
import silverIcon from "../../../media/silver.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import diamondIcon from "../../../media/diamond.png";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import rookie from "../../../media/rookie.png";

function parseJSONSafe(string) {
  try {
    const parsed = JSON.parse(string);
    return parsed;
  } catch (error) {
    return {};
  }
}

const rankIcons = {
  1: bronzeIcon,
  2: silverIcon,
  3: goldIcon,
  4: platinumIcon,
  5: diamondIcon,
  6: blueDiamondIcon,
  7: crownDiamondIcon,
};

const Profile = ({ setUserCredits }) => {
  const { setUser, user } = useContext(UserContext);
  const [showCongratsPopup, setShowCongratsPopup] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [country, setCountry] = useState(parseJSONSafe(user?.country));
  const fileInputRef = useRef();
  const [profileImgUrl, setProfileImgUrl] = useState(user.profileImgUrl);
  const [selectedFile, setSelectedFile] = useState(null);
  const [page, setPages] = useState("profile");
  const navigate = useNavigate();
  const { jwt } = useContext(AuthContext);

  const [twitterUsername, setTwtitterUsername] = useState(
    user?.twitterUsername
  );
  const [telegramUsername, setTelegramUsername] = useState(
    user?.telegramUsername
  );
  const [walletAddress, setWalletAddress] = useState(user?.walletAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState({});
  const [visible, setVisible] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountry(value);
  };
  const isEdited =
    name !== user?.name ||
    email !== user?.email ||
    country !== user?.country ||
    twitterUsername !== user?.twitterUsername ||
    telegramUsername !== user?.telegramUsername ||
    walletAddress !== user?.walletAddress;

  const animationConfig = (duration) => ({
    from: { transform: "translateX(-100%)" },
    to: { transform: visible ? "translateX(0%)" : "translateX(-100%)" },
    config: { duration: duration },
  });

  const animations = [
    useSpring(animationConfig(400)),
    useSpring(animationConfig(450)),
    useSpring(animationConfig(500)),
    useSpring(animationConfig(550)),
    useSpring(animationConfig(600)),
    useSpring(animationConfig(650)),
  ];

  useEffect(() => {
    setVisible(true);
    async function fetchTask() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen: "profile" }),
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setTask(parsedResponse.data[0]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTask();
  }, []);

  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [jwt]);

  async function handleFileUpload(e) {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImgUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }

  async function handleKycSubmit(e) {
    e.preventDefault();
    console.log(country);
    try {
      if (isEdited || selectedFile) {
        setIsLoading(true);
        const formData = new FormData();
        if (selectedFile) formData.append("profileImg", selectedFile);
        if (name) formData.append("name", name);
        if (email) formData.append("email", email);
        if (country) formData.append("country", JSON.stringify(country));
        if (twitterUsername)
          formData.append("twitterUsername", twitterUsername);
        if (telegramUsername)
          formData.append("telegramUsername", telegramUsername);
        if (walletAddress) formData.append("walletAddress", walletAddress);
        formData.append("taskID", task.taskID);

        const response = await fetch(`${SERVER_ENDPOINT}/v1/user/kyc`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          body: formData,
        });
        const parsedResponse = await response.json();
        console.log(parsedResponse);
        if (parsedResponse.status === "SUCCESS") {
          const { credits, kycCompleted, ...rest } = parsedResponse.data;

          if (!task.completed && kycCompleted) {
            setTask({ ...task, completed: true });
            setUserCredits(parseInt(credits));
            setShowCongratsPopup(true);
            setShowConfetti(true);

            setTimeout(() => {
              setShowCongratsPopup(false);
              setShowConfetti(false);
            }, 6000);
          } else toast.success("Profile updated");
          console.log("called");

          setUser((prev) => ({ ...prev, ...rest }));
        } else throw new Error("unable to save details");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed");
    } finally {
      setIsLoading(false);
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "99px",
      padding: "6px 14px",
      color: "white",
      width: "100%",
      marginBottom: "20px",
      border: "1px solid #32303e",
      background: "#FFFFFF1A",
      fontSize: "14px",
      outline: state.isFocused ? "none" : "none", // Remove outline on focus
      boxShadow: state.isFocused ? "none" : "none", // Remove box shadow on focus
      "&:hover": {
        border: "1px solid #32303e",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      background: "#1e1d20",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#32303e" : "#1e1d20",
      color: "white",
      "&:hover": {
        background: "#32303e",
        outline: "none", // Remove outline on hover
      },
    }),
  };

  return (
    <div className="profileMainDiv">
      <div className="overlay">
        <div className="radial1"></div>
        <div className="radial2"></div>
        <div className="profileDetailBox_2rx">
          {isLoading && (
            <div className="profileLoaderDiv">
              <img alt="loader" src="https://i.ibb.co/7rmYqhF/loader.png" />
            </div>
          )}
          <div style={{ padding: "20px" }}>
            <animated.div
              style={useSpring({
                transform: visible ? "translateY(0%)" : "translateY(-100%)",
              })}
            >
              <div className="profileCard_KYC">
                <div className="profileCardImgBox">
                  <img src={handIcon2} alt="" />
                </div>
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "oswald",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    {task.completed ? (
                      <div>KYC COMPLETED</div>
                    ) : (
                      <div>
                        COMPLETE <br /> KYC AND EARN
                      </div>
                    )}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <img src={moneyCoin} alt="" height="100%" width="100%" />
                    </span>
                    <span
                      style={{
                        fontFamily: "oswald",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "white",
                        textDecoration: task.completed ? "line-through" : null,
                      }}
                    >
                      +{task.reward}
                    </span>
                  </div>
                </div>
              </div>
            </animated.div>
            <div className="formBox">
              <div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "18px",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontFamily: "oswald",
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                  >
                    Profile Picture
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <div className="ppBox">
                    <div className="profileContainer">
                      <div className="ppl">
                        <img
                          src={rankIcons[user.rank] || rookie}
                          className="ppLevel"
                          alt="ppLevel"
                        />
                        <div className="profilepic">
                          <img
                            src={profileImgUrl || frogImg}
                            className="frogImg"
                            alt="frogImg"
                          />
                        </div>
                        <div
                          className="camera"
                          style={{
                            background: "red",
                          }}
                        >
                          <img
                            src={cameraIcon}
                            className="cameraIcon"
                            alt="frogImg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleKycSubmit} style={{ width: "100%" }}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                  />
                  <div className="kycInputDiv">
                    <animated.div style={animations[0]}>
                      <p>NAME </p>
                    </animated.div>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                    />
                  </div>
                  <div className="kycInputDiv">
                    <animated.div style={animations[1]}>
                      <p>EMAIL </p>
                    </animated.div>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="kycInputDiv">
                    <animated.div style={animations[5]}>
                      <p> TON Wallet address </p>
                    </animated.div>
                    <input
                      type="text"
                      value={walletAddress}
                      onChange={(e) => setWalletAddress(e.target.value)}
                      placeholder="Wallet address"
                    />
                  </div>
                  <div className="kycInputDiv">
                    <animated.div style={animations[2]}>
                      <p>COUNTRY </p>
                    </animated.div>
                    <Select
                      options={options}
                      value={country}
                      onChange={changeHandler}
                      styles={customStyles}
                    />
                  </div>

                  <div className="kycInputDiv">
                    <animated.div style={animations[3]}>
                      <p>X (TWITTER) USERNAME </p>
                    </animated.div>
                    <input
                      type="text"
                      value={twitterUsername}
                      onChange={(e) => setTwtitterUsername(e.target.value)}
                      placeholder="Twitter User Name"
                    />
                  </div>
                  <div className="kycInputDiv">
                    <animated.div style={animations[4]}>
                      <p>TELEGRAM USERNAME: </p>
                    </animated.div>
                    <input
                      readOnly
                      type="text"
                      value={telegramUsername}
                      onChange={(e) => setTelegramUsername(e.target.value)}
                      placeholder="Telegram User Name"
                    />
                  </div>

                  <button
                    disabled={!isEdited && !selectedFile}
                    type="submit"
                    className="kycInputSubmit kycInputSubmitGo"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          {showCongratsPopup && (
            <div className="profile-congrats-box">
              {showConfetti && (
                <div className="confetti">
                  {" "}
                  <Confetti width={820} recycle={false} />
                </div>
              )}
              <Popup
                src={party_popper}
                greet="CONGRATULATIONS"
                greetMsg="You have won 20,000 Pepe Tap Credits"
              />
            </div>
          )}
          <div className="bottomNav">
            <BottomButtons page={page} setPages={setPages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
