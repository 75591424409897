import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import "../palay.css";
import Wallet from "../wallet";
import BoostIcon from "../../../media/boost_icon.png";
import CreditsMoney from "../../../media/tap_credits.png";
import RankIcon from "../../../media/rank-icon.png";
import Qmark from "../../../media/Qmark.png";
import connIcon from "../../../media/connIcon.png";
import PEPEcoin from "../../../media/PEPEcoin.png";
import levelIcon from "../../../media/level.png";
import level1Icon from "../../../media/level1.png";
import level2Icon from "../../../media/level2.png";
import level3Icon from "../../../media/level3.png";
import level4Icon from "../../../media/level4.png";
import level5Icon from "../../../media/level5.png";
import level6Icon from "../../../media/level6.png";
import level7Icon from "../../../media/level7.png";
import level8Icon from "../../../media/level8.png";
import level9Icon from "../../../media/level9.png";
import level10Icon from "../../../media/level10.png";
import bronzeIcon from "../../../media/bronze.png";
import silverIcon from "../../../media/silver.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import diamondIcon from "../../../media/diamond.png";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import ppLevel from "../../../media/ppLevel.png";
import frogImg from "../../../media/frogImg.png";
import HighEnergyIcon from "../../../media/energy-icon.png";
import LowEnergyIcon from "../../../media/low-energy-icon.png";
import privateSaleButton from "../../../media/private_logo.png";
import { energyRankMapping, SERVER_ENDPOINT } from "../../../utils/constants";
import { useSpring, animated } from "@react-spring/web";
import { IoIosArrowForward } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import { CProgress } from "@coreui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { TonConnectButton } from "@tonconnect/ui-react";
import TonConnect from "@tonconnect/sdk";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { getReadableNumber } from "../../../utils/helpers";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";

const manifestUrl =
  "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

const tonConnect = new TonConnect({
  manifestUrl,
});

const Play = ({
  tap,
  setTap,
  setPages,
  tokenPrice,
  setLastTapAt,
  setFirstTapAt,
  firstTapAt,
  setShowConnectWallet,
  showConnectWallet,
  connectedAddress,
}) => {
  const { user, setUser, userCredits, userEnergy, setUserEnergy } =
    useContext(UserContext);
  const { lastBoostAt, level, name, rank } = user;
  const [waves, setWaves] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [boostTimer, setBoostTimer] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { jwt } = useContext(AuthContext);
  const MAX_ENERGY = energyRankMapping[rank];
  const userPepeCredits = getReadableNumber(user?.pepeCredits);

  const lastBoostAtTimestamp = useMemo(
    () => (lastBoostAt ? moment.utc(lastBoostAt) : null),
    [lastBoostAt]
  );
  const isBoostActive = lastBoostAtTimestamp
    ? moment().utc().diff(lastBoostAtTimestamp, "minutes") < 10
    : false;
  const canBoost = lastBoostAtTimestamp
    ? moment().utc().diff(lastBoostAtTimestamp, "minutes") > 130
    : true;

  const netPerTapEnergyLoss = (level + 1);
  useEffect(() => {
    setVisible(true);
  }, [user]);

  useEffect(() => {
    function updateTimer() {
      if (isBoostActive) {
        const duration = moment.duration(boostEndAt.diff(moment().utc()));
        setBoostTimer({
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      } else if (!canBoost) {
        const duration = moment.duration(nextBoostAt.diff(moment().utc()));
        setBoostTimer({
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }
    }
    const nextBoostAt = moment(lastBoostAtTimestamp).add(130, "minutes");
    const boostEndAt = moment(lastBoostAtTimestamp).add(10, "minutes");
    updateTimer();
    const i1 = setInterval(updateTimer, 1000);
    return () => clearInterval(i1);
  }, [lastBoostAtTimestamp, isBoostActive, canBoost]);

  async function handleBoost() {
    try {
      const response = await fetch(`${SERVER_ENDPOINT}/v1/user/boost`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS")
        setUser((user) => ({
          ...user,
          lastBoostAt: parsedResponse.data.lastBoostAt,
        }));
      else throw new Error("cannot boost");
    } catch (error) {
      console.log(error);
    }
  }

  const getLevelImage = (levelNo) => {
    switch (levelNo) {
      case 0:
        return level1Icon;
      case 1:
        return level2Icon;
      case 2:
        return level3Icon;
      case 3:
        return level4Icon;
      case 4:
        return level5Icon;
      case 5:
        return level6Icon;
      case 6:
        return level7Icon;
      case 7:
        return level8Icon;
      case 8:
        return level9Icon;
      case 9:
        return level10Icon;
    }
  };

  const getFrameImage = (rankNo) => {
    switch (rankNo) {
      case 0:
        return "";
      case 1:
        return bronzeIcon;
      case 2:
        return silverIcon;
      case 3:
        return goldIcon;
      case 4:
        return platinumIcon;
      case 5:
        return diamondIcon;
      case 6:
        return blueDiamondIcon;
      case 7:
        return crownDiamondIcon;
    }
  };

  const handleTap = (e, outSite) => {
    if (userEnergy >= netPerTapEnergyLoss) {
      for (let touch of e.changedTouches) {
        const x_ani = touch.clientX;
        const y_ani = touch.clientY - 30;
        console.log(x_ani, y_ani);
        createAnimation(x_ani, y_ani); // +1, +2 animation
        setTap((tap) => tap + 1);
        setUserEnergy((energy) => energy - netPerTapEnergyLoss);
        const currTime = moment().utc().toISOString();
        setLastTapAt(currTime);
        if (!firstTapAt) setFirstTapAt(currTime);

        if (navigator.vibrate) {
          navigator.vibrate(50); // vibration on tap
        } else if (window.Telegram?.WebApp)
          window.Telegram?.WebApp.HapticFeedback.notificationOccurred(
            "success"
          );

        const rect = e.target.getBoundingClientRect();
        const x = touch.clientX - rect.left; // x position within the element
        const y = touch.clientY - rect.top; // y position within the element

        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const deltaX = x - centerX;
        const deltaY = y - centerY;

        // Calculate rotations based on click position
        const rotateX = (-deltaY / centerY) * 20; // Positive deltaY tilts the top part towards the user
        const rotateY = (deltaX / centerX) * 20; // Positive deltaX tilts the right part towards the user

        if (outSite === false) {
          // Apply the transformation
          e.target.style.transformOrigin = "center";
          e.target.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

          // Reset the transform after a short delay to create a bounce effect
          setTimeout(() => {
            e.target.style.transform = "rotateX(0) rotateY(0)";
          }, 300);
        }
      }
    }
  };

  const createAnimation = (x, y) => {
    const anime = document.createElement("span");
    anime.innerHTML = `+${isBoostActive ? 2 * (level + 1) : level + 1}`;
    anime.className = "anime";
    anime.style.top = `${y}px`;
    anime.style.left = `${x}px`;
    anime.onclick = () => anime.remove();
    document.body.appendChild(anime);

    const animation = anime.animate(
      [
        { visibility: "visible" },
        // { transform: "scale(1.8);" },
        { visibility: "hidden" },
      ],
      { duration: 350 }
    );

    animation.onfinish = () => anime.click();
  };

  const energyAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 300 },
  });
  const boostAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 300 },
  });

  const RankAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 400 },
  });
  const coinAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  const creditAnimation = useSpring({
    transform: toggle ? "scale(1.4)" : "scale(1)",
    marginLeft: toggle ? "20%" : "0px",
  });

  const profileBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0.1 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      // await next({ backdropFilter: "blur(40px)" });
      // await next({ height: "132px" });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(100%)",
      });
    },
    config: { duration: 300 },
  });

  const [barWidth, setBarWidth] = useState(70);

  const handleConnect = () => {
    if (connectedAddress !== "") {
      navigate("/wallet");
    } else {
      setShowConnectWallet(!showConnectWallet);
    }
  };

  return (
    <div className="main-game">
      <div className="color-overlay"></div>
      <div className="content">
        <div
          className="newtap"
          onTouchStart={(e) => {
            handleTap(e, true);
            setToggle(true);
            setTimeout(() => {
              setToggle(false);
            }, 90);
          }}
          onTouchMove={(e) => e.preventDefault()}
        ></div>
        <div className="playFixedDiv">
          <Wallet name={name} />

          {/* <div className="welcome-header">
            <p>
            Welcome, <b>{user?.telegramUsername}</b>
            </p>
            </div> */}
          {/* <animated.div style={{ ...profileBoxAnimation }}> */}
          <div className="pfBar_pfx">
            <ProfileBar />
          </div>
          {/* </animated.div> */}
          {/* <div className="welcomeContainer">
            <div className="welcomeBox">
              <div className="left">
              <div className="lTop">
              <div className="ppBox">
                    <div className="profileContainer">
                      <div className="ppll">
                        <img src={getFrameImage(user.rank)} className="ppLevelll" />
                        <div className="profilepicc">
                          <img
                            src={user?.profileImgUrl || frogImg}
                            className="frogImggg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="welBox">
                    <div className="welText">Welcome</div>
                    <div className="nameBox">
                      <div className="pepeName">{user?.name || user?.telegramUsername || user?.userID}</div>
                      <img src={getLevelImage(user.level)} className="levelIcon" />
                    </div>
                  </div>
                </div>
                <div className="lBottom">
                  <div className="levelText">{user.rankName}</div>
                  <div className="levelscore">
                    <div className="rank">
                      <span>{user.nextRankUpAt - user.invitesToRankUp}</span>/
                      {user.nextRankUpAt}{" "}
                    </div>
                    <div className="progressBox">
                      <div className="circle"></div>
                      <div
                        className="greenBar"
                        style={{
                          width: `${((user.nextRankUpAt - user.invitesToRankUp) /
                            user.nextRankUpAt) *
                            100
                            }% `,
                        }}
                      >
                        <div className="barRank">
                          <IoStar className="starIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/personal"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <IoIosArrowForward className="arrowIcon" />
                  </Link>
                </div>
              </div>
              <div className="right">
                <div className="connectBox">
                  <img src={connIcon} className="connIcon" />
                  <div
                    className="conn"
                    onClick={() => {
                      handleConnect();
                      console.log("connectClick");
                    }}
                  >
                    {connectedAddress !== ""
                      ? connectedAddress.slice(0, 6)
                      : "CONNECT"}
                  </div>
                </div>
                <div className="credBox">
                  <div className="coinImg">
                    <img className="PEPECoinImg" src={PEPEcoin} alt="" />
                  </div>
                  <div className="creds">
                    <div className="pepeTextt">PEPE Credits</div>
                    <div
                      className="pepeCount"
                      style={{
                        display: userPepeCredits != 0 ? "flex" : "none",
                      }}
                    >
                      {userPepeCredits}
                    </div>
                    <Link
                      to="/wallet?buy=true"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="buyButton">BUY</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div
            style={{
              position: "relative",
              width: "92%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <div className="hr-line"></div> */}
            <div className="topCardDiv">
              <div className="card2">
                <div className="topCardCoinDiv">
                  <img className="creditCoinImg" src={CreditsMoney} alt="" />
                </div>
                <div
                  className="mytapcredBox"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <p className="total-credit-text">
                      My Tap Credits{" "}
                      <span style={{ color: "white" }}>
                        {isBoostActive && "x2"}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="total-credit-number">
                      <animated.div style={{ ...creditAnimation }}>
                        {userCredits +
                          tap * (isBoostActive ? 2 : 1) * (level + 1)}
                      </animated.div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div
          style={{
            position: "absolute",
            width: "40px",
            height: "40px",
            top: "15rem",
            left: "0.8rem",
            zIndex: "9999",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(33, 17, 52, 0.6)",
            borderRadius: "50%",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            border: "1px solid rgba(255, 255, 255, 0.5)"
          }}
          onClick={() => navigate("/privatesale")}
        >
          <img
          style={{width: "35px", height: "35px", objectFit: "contain"}}
            alt="privateSaleButton"
            src={privateSaleButton}
            className="privateSaleButton"
          />
        </div>
        <div
          className="beacon tapdiv"
          style={{
            perspective: "1000px",
          }}
        >
          <div
            className="tabImg"
            style={{ top: 0, left: 0 }}
            onTouchStart={(e) => {
              handleTap(e, false);
              setToggle(true);
              setTimeout(() => {
                setToggle(false);
              }, 90);
            }}
          ></div>
          {waves.map((wave) => (
            <div
              key={wave.key}
              className="wave"
              style={{ left: wave.left, top: wave.top }}
            ></div>
          ))}
        </div>

        <div className="paly-data">
          <div className="rank-data">
            <animated.div style={{ ...RankAnimation }}>
              <Link
                to="/personal"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  // onClick={() => setPages("ranking")}
                  style={{
                    marginRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    height: "2.2rem",
                  }}
                >
                  <img
                    alt="rank icon"
                    src={RankIcon}
                    style={{ maxWidth: "16px", marginRight: "5px" }}
                  />
                  <p style={{ fontSize: "13px" }}>RANK</p>
                </div>
              </Link>
            </animated.div>
            <animated.div style={{ ...RankAnimation }}>
              <Link
                to="/faq"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="Qmark">
                  <div>
                    <img
                      alt="Qmark icon"
                      src={Qmark}
                      style={{ maxWidth: "30px" }}
                    />
                  </div>
                </div>
              </Link>
            </animated.div>
          </div>
          <div className="energy-boost-box">
            <animated.div style={{ ...energyAnimation }}>
              <div data-aos="fade-right">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {userEnergy < 500 ? (
                    <img
                      alt="low energy icon"
                      src={LowEnergyIcon}
                      style={{ maxWidth: "30px", marginRight: "5px" }}
                    />
                  ) : (
                    <img
                      alt="high energy icon"
                      src={HighEnergyIcon}
                      style={{ maxWidth: "30px", marginRight: "5px" }}
                    />
                  )}
                  <p style={{ margin: "0", fontSize: "14px" }}>
                    {Math.floor(userEnergy)} / {MAX_ENERGY}
                  </p>
                </div>
              </div>
            </animated.div>

            <div className="right-play-data">
              {/* <animated.div style={{ ...RankAnimation }}>
                <Link to="/faq" style={{ textDecoration: "none", color: "inherit" }} >
                  <div className="Qmark">
                    <div>
                      <img
                        alt="Qmark icon"
                        src={Qmark}
                        style={{ maxWidth: "30px" }}
                      />
                    </div>
                  </div>
                </Link>
              </animated.div> */}
              <animated.div style={{ ...boostAnimation }}>
                <button
                  disabled={!canBoost}
                  onClick={handleBoost}
                  className="boost-button"
                  style={{
                    background: !isBoostActive ? "#12112fc4" : "#23A503",
                    width: "6rem",
                  }}
                >
                  {canBoost ? (
                    <img
                      alt="rank icon"
                      src={BoostIcon}
                      style={{ width: "22px", margin: "0 2px" }}
                    />
                  ) : (
                    <p style={{ fontSize: "18px", margin: "0 8px" }}>⌛</p>
                  )}
                  <p
                    style={{
                      margin: " 0 10px 0 0",
                      padding: "0 ",
                      fontSize: "13px",
                    }}
                  >
                    {canBoost
                      ? "BOOST 2X"
                      : ` ${boostTimer.hours}:${boostTimer.minutes}:${boostTimer.seconds} `}
                  </p>
                </button>
              </animated.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Play;
