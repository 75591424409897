import React, { useContext, useEffect, useState } from "react";
import "./wallet.css";
import ProfileBar from "../Shared/ProfileBar/ProfileBar";
import CreditBalance from "./component/CreditBalance";
import GamePackageCard from "./component/GamePackageCard";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import leftArrow from "../../media/leftArrow.png";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import party_popper from "../../media/party_popper.png";
import Confetti from "react-confetti";
import {
  SERVER_ENDPOINT,
  TON_EXPLORER,
  TON_EXPLORER_KEY,
} from "../../utils/constants";
import ConfirmTxModal from "./ConfirmTxModal";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import rankImg_es from "../../media/gold.png";
import levelImg_es from "../../media/level3.png";
import rankImg_ca from "../../media/diamond.png";
import levelImg_ca from "../../media/level7.png";
import rankImg_la from "../../media/crownDiamond.png";
import levelImg_la from "../../media/level10.png";
import { UserContext } from "../../context/UserContext";
import Popup from "../Shared/CongratsPopup/Popup";
import WalletModal from "../PlayPage/WalletModal";

const Wallet = () => {
  const [walletConnect, setWalletConnect] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [tonUsdValue, setTonUsdValue] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const { jwt } = useContext(AuthContext);
  const { userCredits, setShowConnectWallet, showConnectWallet, setUser } =
    useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userAddress = useTonAddress();
  const [userPackages, setUserPackages] = useState([]);
  const [congratsPopup, setCongratsPopup] = useState(false);
  const [userOwnedPackages, setUserOwnedPackages] = useState([]);
  const imageMappings = [
    {
      id: 3,
      rankImg: rankImg_es,
      levelImg: levelImg_es,
    },
    {
      id: 5,
      rankImg: rankImg_ca,
      levelImg: levelImg_ca,
    },
    {
      id: 7,
      rankImg: rankImg_la,
      levelImg: levelImg_la,
    },
  ];
  const [lastTxnSuccess, setLastTxnSuccess] = useState();
  useEffect(() => {
    async function fetchUserOwnedPackages() {
      try {
        const res = await fetch(`${SERVER_ENDPOINT}/v1/user/packages`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS")
          setUserOwnedPackages(parsedRes.data);
        else throw new Error("Failed to fetch packages");
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserOwnedPackages();
  }, [lastTxnSuccess]);
  useEffect(() => {
    async function fetchPackages() {
      try {
        const res = await fetch(`${SERVER_ENDPOINT}/v1/package`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS") setUserPackages(parsedRes.data);
        else throw new Error("Failed to fetch packages");
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchTokenPrice() {
      try {
        const res = await fetch(
          "https://6mmcf0xaoi.execute-api.eu-central-1.amazonaws.com/dev/public-token-price",
          { method: "GET" }
        );
        const parsedRes = await res.json();
        setTokenPrice(parseFloat(parsedRes.tokenPrice.toFixed(9)));
      } catch (error) {
        console.log(error);
      }
    }
    async function getTonUsdValue() {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/price/ton/usd`, {
          method: "GET",
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "FAILED")
          throw new Error("Failed to fetch usd-ton price");
        setTonUsdValue(parseFloat(parsedResponse.data.usd));
      } catch (error) {
        console.log(error);
      }
    }
    fetchPackages();
    getTonUsdValue();
    fetchTokenPrice();
    const i1 = setInterval(getTonUsdValue, 1000 * 60 * 5);
    const i2 = setInterval(fetchTokenPrice, 1000 * 60 * 5);
    const searchParams = new URLSearchParams(location.search);
    const param = searchParams.get("buy");
    console.log("buy", param);
    if (param == "true") {
      setWalletConnect(true);
    } else {
      setWalletConnect(false);
    }
    return () => {
      clearInterval(i1);
      clearInterval(i2);
    };
  }, [location, navigate]);

  function createTransactionObject(amount) {
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 600,
      messages: [
        {
          address: "UQDFPqaHQKK_uZuU6pHhAGbKV2eOHYxjpKyfY5PlmW0l6sqp", // for test
          // address: "UQChJhuS12aL-kyiqu4onDhFapXIQ4MdJINN23KQ18sQRjAa",
          // UQA-d6zF28paUMDjQlGcjqxZ7elhm-haDIt2NNpLx0FJSCnj
          amount, // amount * 1e9 -> to string
          payload: "",
        },
      ],
    };
    return transaction;
  }

  const goBack = () => {
    navigate("/?noload=true");
  };

  const getLatestTx = async () => {
    if (userAddress !== "") {
      // api key = ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67
      const txResponse1 = await fetch(
        `${TON_EXPLORER}/getTransactions?limit=5&to_lt=0&archival=false&address=${userAddress}`,
        {
          method: "GET",
          headers: {
            "X-API-Key": TON_EXPLORER_KEY, // store in env
          },
        }
      );
      if (txResponse1.ok) {
        const tx = await txResponse1.json();
        return {
          hash: tx?.result?.[0]?.transaction_id.hash,
          lt: tx?.result?.[0]?.transaction_id.lt,
        };
      } else {
        return null;
      }
    } else {
      // handle wallet connection
      await tonConnectUI?.wallet?.connectWallet();
    }
  };
  const handlePurchase = async (packageID, amount) => {
    const latestTx1 = await getLatestTx();
    console.log("Old tx: ", latestTx1);
    try {
      amount = parseFloat(amount.toFixed(9)) * 1e9;
      const resp = await tonConnectUI.sendTransaction(
        createTransactionObject(amount)
      );
      console.log("TX: ", resp);
      // alert('Transaction sent!');
      setShowConfirmModal(true);

      let latestTx2 = latestTx1;
      let counter = 0;
      let counterMax = 7;
      while (
        latestTx2.hash == latestTx1.hash ||
        (latestTx2 == null && counter < counterMax)
      ) {
        latestTx2 = await getLatestTx();
        console.log("new: ", latestTx2);
        await sleep(3000);
        counter++;
      }

      console.log("counter: ", counter);

      if (latestTx2 !== null) {
        console.log("Transaction successfully received", latestTx2);
        // sent this latestTx2 object to backend for verification
        // use the below verify function in backend
        try {
          const resp = await fetch(
            `${SERVER_ENDPOINT}/v1/transaction/success`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                packageID,
                latestTx2,
                userAddress,
                amount,
              }),
            }
          );

          if (resp.status === 200) {
            //CongratsPoupup on
            const parsedRes = await resp.json();
            setCongratsPopup(true);
            setShowConfetti(true);
            setLastTxnSuccess(packageID);
            setUser((user) => ({ ...user, ...parsedRes.data }))
          } else {
            toast.error("Error updating package, contact support");
          }
        } catch (er) {
          toast.error("Error updating package, contact support");
        }
      } else {
        toast.error("Transaction could not be found, contact support");
        console.log("Latest TX found is null", latestTx2);
      }

      setShowConfirmModal(false);
    } catch (error) {
      console.error("Failed to send transaction:", error);
      // alert('Transaction failed!');
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleDisconnect = async () => {
    console.log("called");
    await tonConnectUI.disconnect();
    navigate("/?noload=true");
  };

  return (
    <div className="walletContainer">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "3%",
          width: "100%",
        }}
      >
        <div onClick={goBack} style={{ cursor: "pointer", marginLeft: "15px" }}>
          <img
            src={leftArrow}
            alt="Go back"
            height="20px"
            width="15px"
            className="leftArrowIcon"
          />
        </div>

        <span
          style={{
            fontSize: "16px",
            color: "white",
            fontFamily: "poppins",
            margin: "0px auto",
          }}
        >
          Credit Balance
        </span>
      </div>
      <div className="overlay">
        <div className="radial1"></div>
        <div className="radial2"></div>
      </div>
      <div className="walletContent">
        <div style={{ margin: "0 auto", width: "95%" }}>
          <ProfileBar />
        </div>
        {!walletConnect ? (
          <div
            style={{
              marginBottom: "50px",
            }}
          >
            <CreditBalance
              handleDisconnect={handleDisconnect}
              setWalletConnect={setWalletConnect}
            />
          </div>
        ) : (
          <div
            style={{
              margin: "10px",
            }}
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                color: "white",
              }}
            >
              Purchase Our premium packages. Earn PEPE credits and also rise
              through the ranks faster.
            </span>
          </div>
        )}
        <div
          style={{
            margin: "10px",
          }}
        >
          <p className="gameTitle">GAME PACKAGES</p>
          {userPackages.map((userPackage) => {
            const owned = !!userOwnedPackages.find(
              ({ packageID }) => packageID === userPackage.packageID
            );
            const imageMapping = imageMappings.find(
              (mapping) => mapping.id === userPackage.rank
            );
            return (
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <GamePackageCard
                  rankImg={imageMapping.rankImg}
                  levelImg={imageMapping.levelImg}
                  owned={owned}
                  tonUsdValue={tonUsdValue}
                  userPackage={userPackage}
                  handlePurchase={handlePurchase}
                  tokenPrice={tokenPrice}
                  connectedAddress={userAddress}
                />
              </div>
            );
          })}
        </div>
      </div>
      <ConfirmTxModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        connectedAddress={userAddress}
      />
      {congratsPopup && (
        <div className="wallet-congrats-box">
          {showConfetti && (
            <div className="confetti">
              {" "}
              <Confetti width={820} recycle={false} />
            </div>
          )}
          <Popup
            src={party_popper}
            greet="CONGRATULATIONS"
            greetMsg="You have purchased Game package successfully"
          />
        </div>
      )}
      <WalletModal
        connectedAddress={userAddress}
        showConnectWallet={showConnectWallet}
        setShowConnectWallet={setShowConnectWallet}
      />
    </div>
  );
};

export default Wallet;
