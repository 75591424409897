import React, { useEffect, useState } from "react";
import "./privatesale.css";
import questionFill from "../../../media/question_fill.png";
import tokenomic from "../../../media/tokenomic.png";
import firstImg from "../../../media/first_img_1.png";
import backArrow from "../../../media/backArrow.png";
import PrivateSaleContent from "./privateSaleContent";
import PrivateSaleDetails from "./privateSaleDetails";
import { useNavigate } from "react-router-dom";

const PrivateSale = ({ userID, setIsOpen, setUserCredits }) => {
  const [page, setPages] = useState("friend");
  const [details, setDetails] = useState(false);
  const [isTokenomic, setTokenomic] = useState(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    setPages("privateSale");
  }, []);

  return (
    <div className="main-game">
      <div className="color-overlay"></div>
      <div>
        <div className="privateSaleContainer">
          <div className="overlay">
            <div className="radial1"></div>
            <div className="radial2"></div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#ffffff",
                position: "absolute",
                top: "1rem",
                width: "100%",
                zIndex: "999",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "10%",
                  pointerEvents: "auto",
                  zIndex: "99999",
                  opacity: "1",
                }}
                onClick={() => navigate("/?noload=true")}
              >
                <img
                  alt="backArrow"
                  style={{ width: "24px", height: "24px" }}
                  src={backArrow}
                  className="backArrow"
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  left: "35%",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                {isTokenomic
                  ? "TOKENOMICS"
                  : details
                  ? "JOIN PRIVATE SALE"
                  : "PRIVATE SALE"}
              </div>
            </div>
            <div
              className="privateSaleContent"
              style={{ top: isTokenomic ? "-3rem" : "-7rem" }}
            >
              {!isTokenomic ? (
                <div className="iconSection" style={{marginTop: "10rem", height: "30%", width: "60%"}}>
                  <img alt="firstImg" src={firstImg} className="starImg" />
                </div>
              ) : (
                <div className="iconSection" style={{marginTop: "10rem", height: "30%", width: "60%"}}>
                  <div style={{position: "relative", display: "inline-block", width: "100%", height: "100%"}}>
                  <img
                  style={{display: "block", width: "100%", height: "80%"}}
                    alt="pieChart"
                    src={tokenomic}
                    className={`pie-chart ${loaded ? "loaded" : "rotating"}`}
                    onLoad={handleImageLoad}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "41%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      font: "Oswald",
                      color: "#ffffff",
                      width: "80px",
                      textAlign: "center",
                    }}
                  >
                    Total Supply 
                    888 Billions
                  </div>
                  </div>
                </div>
              )}
              <div className="contentModal3rx">
                <div
                  className="contentModalBox"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow:
                      "0px -5px 30px 0px rgba(41, 39, 130, 0.1), inset 0px 1px 0.5px 0px rgba(255, 255, 255, 0.5)",
                    zIndex: "2",
                    marginBottom: "100px",
                  }}
                >
                  {isTokenomic ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      {[
                        { color: "#3A006B", desc: "46% Private SALE" },
                        { color: "#E0CFE2", desc: "30% Skating" },
                        { color: "#C7A9DB", desc: "7.5% DEX" },
                        { color: "#9361C4", desc: "7.5% CEX" },
                        { color: "#6A2AAC", desc: "5% Game Airdrop" },
                        { color: "#57168C", desc: "2% Advisory" },
                        { color: "#3A006B", desc: "2% Founders" },
                      ].map((value, index) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            margin: "0.5em",
                            marginBottom: index == 6 ? "0.5em" : "0",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "13px",
                              background: "rgba(4, 4, 4, 0.2)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "2%",
                            }}
                          >
                            <div
                              style={{
                                border: "1px solid #FFFFFF",
                                background: `${value.color}`,
                                width: "60%",
                                height: "60%",
                                borderRadius: "13px",
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              marginLeft: "0.5rem",
                              width: "80%",
                              font: "Oswald",
                              color: "#ffffff",
                            }}
                          >
                            {value.desc}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          margin: "2%",
                          left: "88%",
                          padding: "1.75px 0px 0px 0px",
                          position: "relative",
                        }}
                      >
                        <img
                          onClick={() => setTokenomic(true)}
                          style={{ width: "100%", height: "100%" }}
                          alt="question"
                          src={questionFill}
                          className="questionImg"
                        />
                      </div>
                      {!details ? (
                        <PrivateSaleContent
                          details={details}
                          setDetails={setDetails}
                        />
                      ) : (
                        <PrivateSaleDetails
                          details={details}
                          setDetails={setDetails}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateSale;
