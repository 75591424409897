import React, { useContext, useEffect, useState } from "react";
import styles from "./Friend.module.css";
import dancingduck from "../../../media/dancing-duckdancing.gif";
import profilePic from "../../../media/profilePic.png";
import CreditsMoney from "../../../media/silver-pepe-bg.png";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import { TbLink } from "react-icons/tb";
import { RiFileCopyFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useSpring, animated } from "@react-spring/web";
import { MINIAPP_LINK, SERVER_ENDPOINT } from "../../../utils/constants";
import toast from "react-hot-toast";
import { UserContext } from "../../../context/UserContext";
import { useTonAddress } from "@tonconnect/ui-react";
import WalletModal from "../WalletModal";
import { AuthContext } from "../../../context/AuthContext";
import { getReadableNumber } from "../../../utils/helpers";
import { getMobileOperatingSystem } from "../../../utils/helpers";
import ppLevel from "../../../media/ppLevel.png";
import frogImg from "../../../media/frogImg.png";
import bronzeIcon from "../../../media/bronze.png";
import silverIcon from "../../../media/silver.png";
import goldIcon from "../../../media/gold.png";
import platinumIcon from "../../../media/platinum.png";
import diamondIcon from "../../../media/diamond.png";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import rookie from "../../../media/rookie.png";
import bluecheck from "../../../media/bluecheck.png";

const rankIcons = {
  1: bronzeIcon,
  2: silverIcon,
  3: goldIcon,
  4: platinumIcon,
  5: diamondIcon,
  6: blueDiamondIcon,
  7: crownDiamondIcon,
};

const Friend = () => {
  const { user, showConnectWallet, setShowConnectWallet } =
    useContext(UserContext);
  const { referralCode } = user;
  const [selectedOption, setSelectedOption] = useState(null);
  const [sortBy, setSortBy] = useState("credits");
  // const [friends, setFriends] = useState(friendsData);
  const [page, setPages] = useState("friend");
  const [visible, setVisible] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [referredUsers, setReferredUsers] = useState([]);
  const connectedAddress = useTonAddress();
  const { jwt } = useContext(AuthContext);

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const bottomAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const leftAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 400 },
  });

  const rightAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 400 },
  });

  // const handleOptionClick = (index) => {
  //   setSelectedOption(index);
  // };
  useEffect(() => {
    async function fetchReferredUsers() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/user/referredUsers`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setReferredUsers(parsedResponse.data);
          console.log("referred", referredUsers);
        } else throw new Error("Failed to fetch referred users");
      } catch (error) { }
    }
    async function fetchTasks() {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen: "invite" }),
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setTasks(
            parsedResponse.data.sort(
              (t1, t2) => parseInt(t1.reward) - parseInt(t2.reward)
            )
          );
        } else throw new Error("Failed to redeem task");
      } catch (error) {
        console.log(error);
      }
    }
    fetchTasks();
    fetchReferredUsers();
  }, []);

  const handleSortClick = (sortKey) => {
    setSortBy(sortKey);
    const sortedFriends = [...referredUsers].sort((a, b) => {
      if (sortKey === "credits") {
        return b.credits - a.credits;
      } else {
        return b.rank - a.rank;
      }
    });
    setReferredUsers(sortedFriends);
  };

  let credits = 0;

  const forwardMsg = `Hi Amigo!

  Join me in the Pepe Tapping Game and let's have some fun! 🚀
  
  👆 Tap away to earn Pepe Tap Credits and unlock awesome rewards!
  
  🔥 Let's compete and see who can get the highest score!
  
  🔗 Click the link below to join my friend list and start tapping:
  ${MINIAPP_LINK}?startapp=${referralCode}
  
  🎉 Invite your friends and earn more Pepe Tap Credits together. The more, the merrier!
  
  🌟 Special Bonus: Get extra credits when you refer friends!`;

  const truncateName = (name, maxLength = 12) => {
    const nameStr = String(name); // Ensure name is a string
    if (nameStr.length <= maxLength) return nameStr;
    return `${nameStr.slice(0, maxLength)}...`;
  };

  return (
    <div className={styles.body}>
      <div className={styles.overlay}>
        <div className={styles.radial1}></div>
        <div className={styles.radial2}></div>
      </div>
      <div className={styles.statContent}>
        <animated.div style={{ ...topAnimation }}>
          <div className={styles.pfBar}>
            <ProfileBar />
          </div>
        </animated.div>
        <div className={styles.inviteEarnBox}>
          <animated.div style={{ ...rightAnimation }}>
            <div className={styles.inviteEarnHeader}>INVITE & EARN</div>
          </animated.div>
          <animated.div style={{ ...rightAnimation }}>
            <div className={styles.inviteOptions}>
              {tasks.map(({ name, reward, completed }, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.inviteOption} ${completed ? styles.completed : ""
                      }`}
                  // onClick={() => handleOptionClick(index)}
                  >
                    <div className={styles.inviteText}>
                      {name}
                      {completed && (
                        <IoIosCheckmarkCircle className={styles.checkMark} />
                      )}
                    </div>
                    <div className={styles.inviteAmount}>
                      <div>
                        <img src={CreditsMoney} alt="Credits" />
                      </div>
                      <div className={styles.amount}>{reward}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </animated.div>
          <animated.div style={{ ...leftAnimation }}>
            <div className={styles.inviteButtons}>
              <div
                onClick={() => {
                  if (getMobileOperatingSystem() == "iOS") {
                    window.location.href = `https://t.me/share/url?url=${encodeURIComponent(
                      forwardMsg
                    )}`;
                  } else {
                    window.open(
                      `https://t.me/share/url?url=${encodeURIComponent(
                        forwardMsg
                      )}`
                    );
                  }
                }}
                className={styles.inviteButton}
              >
                <TbLink className={styles.linkIcon} />
                INVITE FRIENDS
              </div>
              <div
                onClick={() =>
                  navigator.clipboard
                    .writeText(`${MINIAPP_LINK}?startapp=${referralCode}`)
                    .then(() => toast.success("Copied"))
                }
                className={`${styles.inviteButton2} ${styles.copy}`}
              >
                <RiFileCopyFill className={styles.copyIcon} />
              </div>
            </div>
          </animated.div>
        </div>
        <div className={styles.invitedBox}>
          <animated.div style={{ ...bottomAnimation }}>
            <div className={styles.invitedHeader}>
              INVITED FRIENDS ({referredUsers.length})
              <div className={styles.sortBox}>
                <div
                  className={`${styles.sortOption} ${sortBy === "credits" ? styles.activeSort : ""
                    }`}
                  onClick={() => handleSortClick("credits")}
                >
                  <IoIosCheckmarkCircle className={styles.checkMark2} />
                  <div className={styles.item}>CREDITS</div>
                </div>
                <div
                  className={`${styles.sortOption} ${sortBy === "ranks" ? styles.activeSort : ""
                    }`}
                  onClick={() => handleSortClick("ranks")}
                >
                  <IoIosCheckmarkCircle className={styles.checkMark2} />
                  <div className={styles.item}>RANKS</div>
                </div>
              </div>
            </div>

            <div className={styles.invitedList}>
              {referredUsers?.length > 0 ? (
                referredUsers.map(
                  (
                    { name, credits, profileImgUrl, userID, online, rank },
                    index
                  ) => (
                    <div key={index} className={styles.invitedItem}>
                      {/* <img src={(profileImgUrl ? profileImgUrl : profilePic)} alt="Profile" className={styles.profilePic} /> */}
                      <div className={styles.ppl}>
                        <img
                          src={rankIcons[rank] || rookie}
                          className={styles.ppLevel}
                          alt="ppLevel"
                        />
                        <div className={styles.profilepic}>
                          <img
                            src={profileImgUrl ? profileImgUrl : frogImg}
                            alt={name}
                            className={styles.profilePic}
                          />
                        </div>
                      </div>
                      <div className={styles.userInfo}>
                        <div className={styles.userName}>
                          {truncateName(name ? name : userID)}
                        <img
                          src={bluecheck}
                          className={styles.checkIcon}
                          alt="verifiedIcon"
                        />
                        </div>
                      </div>
                      <div className={styles.userCredits}>
                        <img
                          src={CreditsMoney}
                          alt="Credits"
                          className={styles.CreditsMoney}
                        />
                        <div className={styles.creditsAmount}>
                          {getReadableNumber(credits)}
                        </div>
                      </div>
                      <div className={styles.userStatus}>
                        <div
                          className={`${online ? styles.statusDot : styles.statusDot2
                            }`}
                        ></div>
                        {online ? "ONLINE" : "OFFLINE"}
                      </div>
                    </div>
                  )
                )
              ) : (
                <div
                  style={{
                    width: "50%",
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={dancingduck}
                    style={{
                      maxWidth: "60px",
                      objectFit: "cover",
                      margin: "0 auto",
                    }}
                  />
                  <p>Empty friend list. Go and invite some friends...</p>
                </div>
              )}
            </div>
          </animated.div>
        </div>
        <WalletModal
          connectedAddress={connectedAddress}
          showConnectWallet={showConnectWallet}
          setShowConnectWallet={setShowConnectWallet}
        />
        <div className={styles.bottomNav}>
          <BottomButtons page={page} setPages={setPages} />
        </div>
      </div>
    </div>
  );
};

export default Friend;